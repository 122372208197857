import { RatingsSettingsTabName } from './settings-types';

export const OPEN_TAB_EVENT = 'rs-open-tab-event' as const;

export const openTab = (tabName: RatingsSettingsTabName) => ({
  type: OPEN_TAB_EVENT,
  payload: { tabName },
});

export type RatingsSummarySettingsEvent = ReturnType<typeof openTab>;
