import { SET_CURRENT_SETTINGS_TAB } from './settings-actions';
import { Settings, SettingsActions } from './settings-types';

export const settingsReducer = (state: Settings = {}, action: SettingsActions): Settings => {
  switch (action.type) {
    case SET_CURRENT_SETTINGS_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    default:
      return state;
  }
};
