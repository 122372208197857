import { RatingsSummaryBinding } from '~/shared-app-state/shared-app-state';

export const createControllerActions = (
  getSharedStore: (resourceId: string) => RatingsSummaryBinding,
) => ({
  focusReviewsComponent: (resourceId: string) => {
    const sharedState = getSharedStore(resourceId);
    const ratingState = sharedState.getState();
    if (ratingState.type === 'READY' && ratingState.focusReviewsComponent) {
      ratingState.focusReviewsComponent();
    }
  },
});
