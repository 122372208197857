import { some, every, omit } from 'lodash';
import { LayoutActions, LayoutState } from './layout-types';
import { REGISTER_CONTAINER, UNREGISTER_CONTAINER, REGISTER_STARS_WIDTH } from './layout-actions';

export const layoutReducer = (
  state: LayoutState = { starsWidth: 0, containerWidths: {}, isCollapsed: false },
  action: LayoutActions,
): LayoutState => {
  switch (action.type) {
    case REGISTER_STARS_WIDTH:
      return {
        ...state,
        starsWidth: action.payload,
      };
    case REGISTER_CONTAINER:
      const { resourceId, width } = action.payload;
      const containerWidths = {
        ...state.containerWidths,
        [resourceId]: width,
      };
      let isCollapsed = state.isCollapsed;
      if (some(containerWidths, (w) => w < state.starsWidth)) {
        isCollapsed = true;
      } else if (every(containerWidths, (w) => w >= state.starsWidth)) {
        isCollapsed = false;
      }
      return {
        ...state,
        containerWidths,
        isCollapsed,
      };
    case UNREGISTER_CONTAINER:
      return {
        ...state,
        containerWidths: omit(state.containerWidths, action.payload.resourceId),
      };
    default:
      return state;
  }
};
