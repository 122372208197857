import {
  registerContainer,
  registerStarsWidth,
  unregisterContainer,
} from './layout/layout-actions';

export const storeActions = {
  registerStarsWidth,
  registerContainer,
  unregisterContainer,
};
