// It abstracts away the complexity of the configuration setting via exports
// Each parameter is set separately, so here we try to provide batching for
// changes and possibility to to get parameters when they are set

import { Result } from '~/ts-utils';
import { initDebouncedPubSub } from '~commons/debounced-pub-sub';
import { Alignment } from '~ratings/common-types';

export type ConfigSingleResource = {
  namespace: string;
  resourceId: string;
  resourceLink?: string;
  alignment?: Alignment;
};

export const createConfigurationStoreSingle = ({
  defaultNamespace,
  defaultResourceId,
  defaultWaitForResourceId,
}: {
  defaultNamespace: string;
  defaultResourceId: string;
  defaultWaitForResourceId: boolean;
}) => {
  let configState:
    | { type: 'initialized'; value: ConfigSingleResource }
    | {
        type: 'not_initialized';
        value: ConfigSingleResource;
      } = {
    type: 'not_initialized',
    value: {
      namespace: defaultNamespace,
      resourceId: defaultResourceId,
    },
  };
  const pubSub = initDebouncedPubSub<ConfigSingleResource>();

  return {
    setNamespace: (namespace?: string) => {
      let val = namespace;
      if (!val) {
        console.error(
          `Namespace cannot be empty. Falling back to default namespace (${defaultNamespace})`,
        );
        val = defaultNamespace;
      }
      configState.value.namespace = val;
      if (configState.type === 'initialized') {
        pubSub.publish(configState.value);
      }
    },
    setResourceId: (resourceId?: string) => {
      let val = resourceId;
      if (!val) {
        console.error(
          `Resource ID cannot be empty. Falling back to default resource ID (${defaultResourceId})`,
        );
        val = defaultResourceId;
      }
      configState = {
        type: 'initialized',
        value: {
          ...configState.value,
          resourceId: val,
        },
      };
      pubSub.publish(configState.value);
    },
    setResourceLink: (resourceLink?: string) => {
      configState.value.resourceLink = resourceLink;
      if (configState.type === 'initialized') {
        pubSub.publish(configState.value);
      }
    },
    setAlignment: (alignment: Alignment) => {
      configState.value.alignment = alignment;
      pubSub.publish(configState.value);
    },
    getConfiguration: async (): Promise<Result<ConfigSingleResource>> => {
      return configState.type !== 'initialized' && defaultWaitForResourceId
        ? pubSub.waitForFirstPub(() => configState.type === 'initialized')
        : { type: 'ok', value: configState.value };
    },
    getConfigState: () => ({ ...configState }),
    subscribe: pubSub.subscribe,
  };
};
