export const REGISTER_STARS_WIDTH = 'REGISTER_STARS_WIDTH' as const;
export const registerStarsWidth = (payload: number) => ({
  type: REGISTER_STARS_WIDTH,
  payload,
});

export const REGISTER_CONTAINER = 'REGISTER_CONTAINER' as const;
export const registerContainer = (payload: { resourceId: string; width: number }) => ({
  type: REGISTER_CONTAINER,
  payload,
});

export const UNREGISTER_CONTAINER = 'UNREGISTER_CONTAINER' as const;
export const unregisterContainer = (payload: { resourceId: string }) => ({
  type: UNREGISTER_CONTAINER,
  payload,
});
