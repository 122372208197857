import { EnvironmentActions, Environment } from './environment-types';
import { SET_ENVIRONMENT } from './environment-actions';

export const environmentReducer = (
  state: Environment = { shouldShowEmptyState: false },
  action: EnvironmentActions,
) => {
  switch (action.type) {
    case SET_ENVIRONMENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
