import { ControllerParams } from '@wix/yoshi-flow-editor';
import { RatingsSummaryBinding, SharedAppStateStore } from '~/shared-app-state/shared-app-state';
import { RatingsSummaryOoiListSlotInterface, ResourcePublicConfig } from '../../common-types';
import { forEach, difference, intersection, debounce } from 'lodash';
import type { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';
import { createConfigurationStoreMulti } from './configuration-store-multi';
import { SlotContext } from '~/types/common-types';
import { getEnvParams } from '~commons/get-env-params';
import {
  addRating,
  preserveRatings,
} from '~ratings/store/multi/rating-states/rating-states-actions';
import { SettingsEventHandler } from '~commons/settings/create-settings-event-handler';
import {
  OPEN_TAB_EVENT,
  RatingsSummarySettingsEvent,
} from '~ratings/Settings/commons/settings-events';
import { setEnvironment } from '~ratings/store/multi/environment/environment-actions';
import { createStoreMulti } from '~ratings/store/multi/create-store';
import { bindActionCreators } from 'redux';
import { decorateActionsWithLogger } from '~commons/lib/monitoring';
import { storeActions } from '~ratings/store/multi/actions';
import { createControllerActions } from '~ratings/controller/multi/controller-actions';

export const createControllerMulti = async ({
  controllerParams,
  defaultNamespace,
  sharedAppStateStore,
  settingsEventHandler,
}: {
  controllerParams: ControllerParams;
  defaultNamespace: string;
  sharedAppStateStore?: SharedAppStateStore;
  settingsEventHandler: SettingsEventHandler<RatingsSummarySettingsEvent>;
}): Promise<IWidgetController> => {
  let sharedStoreBindingMap: Record<string, RatingsSummaryBinding> | undefined;
  const flowAPI = controllerParams.flowAPI;
  const { wixCodeApi, compId } = flowAPI.controllerConfig;
  const { isSSR, isDebug, logNetwork } = getEnvParams(wixCodeApi);

  const slotContext: SlotContext | undefined =
    flowAPI.controllerConfig.config.publicData.COMPONENT?.slotContext;
  const warmupResourceListKey = `wix-ratings-summary-multi-${compId}-resourceList`;
  const warmupNamespaceKey = `wix-ratings-summary-multi-${compId}-namespace`;
  const warmupResourceList = isSSR ? null : wixCodeApi.window.warmupData.get(warmupResourceListKey);
  const warmupNamespace = isSSR ? null : wixCodeApi.window.warmupData.get(warmupNamespaceKey);
  const configurationStore = createConfigurationStoreMulti({
    defaultResourceList: warmupResourceList,
    defaultNamespace: warmupNamespace ?? defaultNamespace,
    defaultWaitForResourceId:
      slotContext?.type === 'slot' && !flowAPI.environment.isEditor && isSSR,
  });

  const exports = (): RatingsSummaryOoiListSlotInterface => {
    return {
      set resourceList(val: ResourcePublicConfig[]) {
        configurationStore.setResourceList(val);
        isSSR && wixCodeApi.window.warmupData.set(warmupResourceListKey, val);
      },
      set namespace(val: string) {
        configurationStore.setNamespace(val);
        isSSR && wixCodeApi.window.warmupData.set(warmupNamespaceKey, val);
      },
    };
  };
  if (process.env.NODE_ENV === 'test') {
    // Yoshi testkit doesn't expose exports :(
    // @ts-expect-error
    window.exposeExports && window.exposeExports(exports());
  }
  return {
    async pageReady() {
      const setProps = flowAPI.controllerConfig.setProps;
      const { isEditor, isPreview } = flowAPI.environment;
      const store = createStoreMulti(
        debounce((newState) => setProps({ type: 'multi_state', state: newState, actions })),
      );
      const actions = decorateActionsWithLogger(
        {
          ...bindActionCreators(storeActions, store.dispatch),
          ...createControllerActions((resourceId) => {
            if (!sharedStoreBindingMap) {
              throw new Error(
                `sharedStoreBindingMap not initialized. Failed to get sharedState for resourceId ${resourceId}`,
              );
            }
            return sharedStoreBindingMap[resourceId];
          }),
        },
        flowAPI.errorMonitor,
        isDebug || logNetwork === 'error',
      );

      if (!sharedAppStateStore) {
        // Something wrong with environment
        setProps({ ratingState: { type: 'IDLE' }, type: 'single_state' });
      }

      if (isSSR) {
        // SSR currently doesn't support context passing in RATINGS_SUMMARY_OOI_LIST
        setProps({
          type: 'single_state',
          ratingState: { type: 'IDLE' },
        });
        return;
      }

      setProps({
        type: 'multi_state',
        state: store.getState(),
        actions,
      });

      if (isEditor || isPreview) {
        settingsEventHandler.on(OPEN_TAB_EVENT, (payload) => {
          store.dispatch(setEnvironment({ shouldShowEmptyState: payload.tabName === 'Display' }));
        });
        settingsEventHandler.onSettingsClose(() => {
          store.dispatch(setEnvironment({ shouldShowEmptyState: false }));
        });
      }

      const configResult = await configurationStore.getConfiguration();

      if (configResult.type === 'error') {
        const error = new Error(configResult.error);
        flowAPI.errorMonitor.captureException(error, {
          tags: {
            warmupResourceList: warmupResourceList ?? 'NONE',
            warmupNamespace: warmupNamespace ?? 'NONE',
            type: 'Rating summary controller multi',
            url: wixCodeApi.location.url,
            configState: JSON.stringify(configurationStore.getConfigState()),
          },
        });
        throw error;
      }
      let config = configResult.value;

      sharedStoreBindingMap = config.resourceList.reduce((acc, resource) => {
        acc[resource.id] = sharedAppStateStore!.registerRatingsSummary({
          resourceId: resource.id,
          namespace: config.namespace,
          onChange: (ratingState) =>
            store.dispatch(addRating({ resourceId: resource.id, ratingState })),
        });
        return acc;
      }, {} as Record<string, RatingsSummaryBinding>);

      configurationStore.subscribe((newConfig) => {
        const oldResourceIds = config.resourceList.map((r) => r.id);
        config = newConfig;
        const newResourceIds = config.resourceList.map((r) => r.id);

        const idsToPreserve = intersection(oldResourceIds, newResourceIds);
        const idsToUnregister = difference(oldResourceIds, idsToPreserve);
        const idsToRegister = difference(newResourceIds, oldResourceIds);

        store.dispatch(preserveRatings(idsToPreserve));

        idsToUnregister.forEach((resourceId) => {
          // resourceId doesn't exist anymore - we unregister it
          sharedStoreBindingMap?.[resourceId].unregister();
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          sharedStoreBindingMap?.[resourceId] && delete sharedStoreBindingMap[resourceId];
        });

        idsToRegister.forEach((resourceId) => {
          // new resourceId - we register it and add to state
          (sharedStoreBindingMap as Record<string, RatingsSummaryBinding>)[resourceId] =
            sharedAppStateStore!.registerRatingsSummary({
              resourceId,
              namespace: config.namespace,
              onChange: (ratingState) => store.dispatch(addRating({ resourceId, ratingState })),
            });
        });
      });
    },
    onBeforeUnLoad() {
      forEach(sharedStoreBindingMap, (binding) => binding.unregister());
    },
    updateConfig(_, { publicData }) {
      settingsEventHandler.updateData(publicData.COMPONENT ?? {});
    },
    exports,
  };
};
