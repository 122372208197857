import { createStore } from 'redux';
import { rootReducer } from './reducer';
import { StateMulti, StoreMulti } from '~ratings/store/multi/store-types';

export const createStoreMulti = (listener: (state: StateMulti) => void): StoreMulti => {
  const store = createStore(rootReducer);
  let oldState: StateMulti;
  store.subscribe(() => {
    const newState: StateMulti = store.getState();
    if (oldState === newState) {
      return;
    }
    oldState = newState;
    listener(newState);
  });

  return store;
};
