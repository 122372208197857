import { combineReducers } from 'redux';
import { layoutReducer } from './layout/layout-reducer';
import { ratingStatesReducer } from './rating-states/rating-states-reducer';
import { environmentReducer } from './environment/environment-reducer';

export const rootReducer = combineReducers({
  layout: layoutReducer,
  ratingStates: ratingStatesReducer,
  environment: environmentReducer,
});
