import { createStore } from 'redux';
import { rootReducer } from './reducer';
import { StateSingle } from './store-types';

export const createStoreSingle = (listener: (state: StateSingle) => void) => {
  const store = createStore(rootReducer);
  let oldState: StateSingle;
  store.subscribe(() => {
    const newState: StateSingle = store.getState();
    if (oldState === newState) {
      return;
    }
    oldState = newState;
    listener(newState);
  });

  return { store };
};
