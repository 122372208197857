import { RatingState } from '~commons/common-types';
import { SET_RATING_STATE } from './rating-state-actions';
import { RatingActions } from './rating-state-types';

export const ratingStateReducer = (
  state: RatingState = { type: 'IDLE' },
  action: RatingActions,
): RatingState => {
  switch (action.type) {
    case SET_RATING_STATE:
      return action.payload;
    default:
      return state;
  }
};
