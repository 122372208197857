import type { IWidgetController } from '@wix/native-components-infra/dist/src/types/types';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { RatingsSummaryBinding, SharedAppStateStore } from '~/shared-app-state/shared-app-state';
import { Alignment, RatingsSummarySetProps, RatingsSummarySlotInterface } from '../../common-types';
import { addNavigation } from './add-navigation';
import { createConfigurationStoreSingle } from './configuration-store-single';
import { SlotContext } from '~/types/common-types';
import { STORES_APP_IDS, STORES_NAMESPACE } from '~/external-ids';
import { getSeoTags } from './seo-tags';
import { SettingsEventHandler } from '~commons/settings/create-settings-event-handler';
import {
  OPEN_TAB_EVENT,
  RatingsSummarySettingsEvent,
} from '~ratings/Settings/commons/settings-events';
import { getEnvParams } from '~commons/get-env-params';
import { listAttributeAveragesByEntity } from '@wix/ambassador-ratings-v1-rating/http';
import { RatingState } from '~commons/common-types';
import { debounce } from 'lodash';
import { setCurrentSettingsTab } from '~ratings/store/single/settings-state/settings-actions';
import { StateSingle } from '~ratings/store/single/store-types';
import { setRatingState } from '~ratings/store/single/rating-state/rating-state-actions';
import { createStoreSingle } from '~ratings/store/single/create-store';

export const createControllerSingle = async ({
  controllerParams,
  defaultNamespace,
  sharedAppStateStore,
  settingsEventHandler,
}: {
  controllerParams: ControllerParams;
  defaultNamespace: string;
  sharedAppStateStore?: SharedAppStateStore;
  settingsEventHandler: SettingsEventHandler<RatingsSummarySettingsEvent>;
}): Promise<IWidgetController> => {
  let sharedStoreBinding: RatingsSummaryBinding | undefined;
  const flowAPI = controllerParams.flowAPI;
  const { wixCodeApi, config, compId } = flowAPI.controllerConfig;
  const { isSSR } = getEnvParams(wixCodeApi);

  const slotContext: SlotContext | undefined = config.publicData.COMPONENT?.slotContext;
  const warmupResourceIdKey = `wix-ratings-summary-single-${compId}-resourceId`;
  const warmupNamespaceKey = `wix-ratings-summary-single-${compId}-namespace`;
  const warmupResourceId = isSSR ? null : wixCodeApi.window.warmupData.get(warmupResourceIdKey);
  const warmupNamespace = isSSR ? null : wixCodeApi.window.warmupData.get(warmupNamespaceKey);
  const configurationStore = createConfigurationStoreSingle({
    defaultNamespace: warmupNamespace || defaultNamespace,
    defaultResourceId: warmupResourceId || wixCodeApi.site.currentPage?.id || 'default',
    defaultWaitForResourceId:
      slotContext?.type === 'slot' && !flowAPI.environment.isEditor && isSSR,
  });

  const exports = (): RatingsSummarySlotInterface => {
    return {
      set resourceId(val: string) {
        configurationStore.setResourceId(val);
        isSSR && wixCodeApi.window.warmupData.set(warmupResourceIdKey, val);
      },
      set resourceLink(val: string) {
        configurationStore.setResourceLink(val);
      },
      set namespace(val: string) {
        configurationStore.setNamespace(val);
        isSSR && wixCodeApi.window.warmupData.set(warmupNamespaceKey, val);
      },
      set alignment(val: Alignment) {
        configurationStore.setAlignment(val);
      },
    };
  };
  if (process.env.NODE_ENV === 'test') {
    // Yoshi testkit doesn't expose exports :(
    // @ts-expect-error
    window.exposeExports && window.exposeExports(exports());
  }
  return {
    async pageReady() {
      const { isEditor, isPreview } = flowAPI.environment;

      const configResult = await configurationStore.getConfiguration();

      if (configResult.type === 'error') {
        const error = new Error(configResult.error);
        flowAPI.errorMonitor.captureException(error, {
          tags: {
            warmupResourceId: warmupResourceId ?? 'NONE',
            warmupNamespace: warmupNamespace ?? 'NONE',
            type: 'Rating summary controller single',
            url: wixCodeApi.location.url,
            configState: JSON.stringify(configurationStore.getConfigState()),
          },
        });
        throw error;
      }

      const setProps = (state: StateSingle) => {
        const { ratingState, settings } = state;
        const config = configurationStore.getConfigState();
        (flowAPI.controllerConfig.setProps as RatingsSummarySetProps)({
          type: 'single_state',
          ratingState: settings.currentTab === 'Display' ? { type: 'READY_EMPTY' } : ratingState,
          alignment: config.value.alignment,
        });
      };

      const { store } = createStoreSingle(debounce(setProps));

      if (isEditor || isPreview) {
        configurationStore.subscribe((config) => {
          if (config.alignment) {
            setProps(store.getState());
          }
        });
        settingsEventHandler.on(OPEN_TAB_EVENT, (payload) => {
          store.dispatch(setCurrentSettingsTab(payload.tabName));
        });
        settingsEventHandler.onSettingsClose(() => {
          store.dispatch(setCurrentSettingsTab());
        });
      }
      if (!sharedAppStateStore) {
        // Something wrong with environment
        store.dispatch(setRatingState({ type: 'IDLE' }));
      }

      let config = configResult.value;

      const dispatchSetRatingState = (ratingState: RatingState) => {
        store.dispatch(
          setRatingState(
            addNavigation({
              location: wixCodeApi.location,
              ratingState,
              link: config.resourceLink,
              namespace: config.namespace,
              resourceId: config.resourceId,
            }),
          ),
        );
      };

      if (isSSR) {
        try {
          const response = await flowAPI.httpClient.request(
            listAttributeAveragesByEntity({
              group: config.namespace,
              entityIds: [config.resourceId],
              namespace: 'reviews',
              attributeId: 'overall',
              attributeName: 'overall',
            }),
          );
          const { average: overall = 0, total: totalReviews = 0 } =
            response.data.entityAverages?.[0] ?? {};
          if (
            slotContext?.type === 'slot' &&
            STORES_APP_IDS.includes(slotContext?.appDefinitionId ?? '')
          ) {
            wixCodeApi.seo.renderSEOTags(
              getSeoTags({
                resourceId: config.resourceId,
                namespace: config.namespace,
                summary: {
                  overall,
                  totalReviews,
                },
              }),
            );
          }
          dispatchSetRatingState(
            totalReviews > 0
              ? {
                  type: 'READY',
                  overall,
                  totalReviews,
                }
              : { type: 'READY_EMPTY' },
          );
        } catch (e) {
          dispatchSetRatingState({ type: 'ERROR' });
        }
        return;
      }

      sharedStoreBinding = sharedAppStateStore?.registerRatingsSummary({
        resourceId: config.resourceId,
        namespace: config.namespace,
        onChange: (ratingState) => {
          if (config.namespace === STORES_NAMESPACE && ratingState.type === 'READY') {
            if (
              slotContext?.type === 'slot' &&
              STORES_APP_IDS.includes(slotContext?.appDefinitionId ?? '')
            ) {
              // ready ratingState is unavailable elsewhere, so we renderSeoTags here
              wixCodeApi.seo.renderSEOTags(
                getSeoTags({
                  resourceId: config.resourceId,
                  namespace: config.namespace,
                  summary: ratingState,
                }),
              );
            }
          }
          dispatchSetRatingState(ratingState);
        },
      });
      configurationStore.subscribe((args) => {
        config = args;
        sharedStoreBinding?.reconfigure({
          resourceId: config.resourceId,
          namespace: config.namespace,
        });
      });
    },
    onBeforeUnLoad() {
      sharedStoreBinding?.unregister();
    },
    updateConfig(_, { publicData }) {
      settingsEventHandler.updateData(publicData.COMPONENT ?? {});
    },
    exports,
  };
};
