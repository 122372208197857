import { RatingState } from '~commons/common-types';
import { ILocation } from '@wix/native-components-infra/dist/src/types/types';
import { Result } from '~/ts-utils';

// if link is passed we override 'focusReviewsComponent' callback with given link
export const addNavigation = ({
  location,
  ratingState,
  link,
  namespace,
  resourceId,
}: {
  location: ILocation;
  ratingState: RatingState;
  link?: string;
  namespace: string;
  resourceId: string;
}) => {
  if (ratingState.type !== 'READY' || !link) {
    return ratingState;
  }
  const urlResult = createUrlToFocusReviewsComponent({
    baseUrl: location.baseUrl,
    link,
    namespace,
    resourceId,
  });
  if (urlResult.type === 'error') {
    return ratingState;
  }
  return {
    ...ratingState,
    focusReviewsComponent: () => {
      location.navigateTo?.({ url: urlResult.value });
    },
  };
};

const createUrlToFocusReviewsComponent = ({
  baseUrl,
  link,
  namespace,
  resourceId,
}: {
  baseUrl: string;
  link: string;
  namespace: string;
  resourceId: string;
}): Result<string, Error> => {
  try {
    const url = new URL(link, baseUrl);
    url.searchParams.set('wrFocusNs', namespace);
    url.searchParams.set('wrFocusResId', resourceId);
    return { type: 'ok', value: url.toString() };
  } catch (e) {
    return { type: 'error', error: e as Error };
  }
};
