import { omit } from 'lodash';
import { RatingState } from '~commons/common-types';

export const ADD_RATING = 'ADD_RATING' as const;
export const addRating = ({
  resourceId,
  ratingState,
}: {
  resourceId: string;
  ratingState: RatingState;
}) => ({
  type: ADD_RATING,
  payload: {
    resourceId,
    ratingState:
      ratingState.type === 'READY' ? omit(ratingState, 'focusReviewsComponent') : ratingState,
  },
});

export const PRESERVE_RATINGS = 'PRESERVE_RATINGS' as const;
export const preserveRatings = (payload: string[]) => ({
  type: PRESERVE_RATINGS,
  payload,
});
