import { pick } from 'lodash';
import { RatingActions, RatingState } from './rating-states-types';
import { ADD_RATING, PRESERVE_RATINGS } from './rating-states-actions';

export const ratingStatesReducer = (
  state: { [resourceId: string]: RatingState } = {},
  action: RatingActions,
) => {
  switch (action.type) {
    case PRESERVE_RATINGS:
      return pick(state, action.payload);
    case ADD_RATING:
      return {
        ...state,
        [action.payload.resourceId]: action.payload.ratingState,
      };
    default:
      return state;
  }
};
