import { isEqual, cloneDeep } from 'lodash';
import { Result, Option } from '~/ts-utils';

// Invokes the listeners in a debounced manner. Useful for cases when you want
// to handle configuration coming in peaces
export const initDebouncedPubSub = <StateType>() => {
  const listeners: ((args: StateType) => void)[] = [];
  const listenersOnce: ((args: StateType) => void)[] = [];

  let lastNotificationSentWith: Option<StateType> = {
    type: 'none',
  };

  const triggerOnChange = (callbackArgs: StateType) => {
    Promise.resolve().then(() => {
      if (
        lastNotificationSentWith.type === 'none' ||
        !isEqual(lastNotificationSentWith.value, callbackArgs)
      ) {
        listeners.forEach((cb) => cb(callbackArgs));
        listenersOnce.forEach((cb) => cb(callbackArgs));
        listenersOnce.length = 0;
        lastNotificationSentWith = { type: 'some', value: cloneDeep(callbackArgs) };
      }
    });
  };

  const waitForFirstPub = async (
    validate?: (s: StateType) => boolean,
  ): Promise<Result<StateType>> => {
    return new Promise((resolve) => {
      listenersOnce.push((args) => {
        if (!validate || validate(args)) {
          resolve({ type: 'ok', value: args });
        } else {
          return waitForFirstPub(validate);
        }
      });
      setTimeout(() => {
        resolve({ type: 'error', error: 'timeout waiting for configuration' });
      }, 10000);
    });
  };

  return {
    subscribe: (cb: (arg: StateType) => void) => {
      listeners.push(cb);
    },
    waitForFirstPub,
    publish: (value: StateType) => {
      triggerOnChange(value);
    },
  };
};
